<template>
   <div>
      <big-title>
         Retirar <span>fondos</span>.
      </big-title>
      <!-- PANEL DE CUMPLIMIENTO -->
      <compliance-banner class="mb-4"/>

      <sub-title>
         Tu saldo actual es de <span>{{formatBalance}} MXN</span>.
      </sub-title>

      <div v-if="validAccounts.length > 0 && isMounted">
          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <vx-card>
                <div class="vx-row mb">
                  <div class="vx-col w-full">
                    <p class="bold">¡No olvides tener a la mano tu Segundo Factor de Autenticación!</p>
                  </div>
                </div>
              </vx-card>
            </div>
          </div>
          <vx-card class="mt-4" title="¿Cuánto quieres retirar?" >
            <div class="vx-row">
                <div class="vx-col xxl:w-2/5 lg:w-3/5 md:w-2/3 sm:w-full w-full mb-5" >
                  <label class="vs-input--label">Monto de retiro *</label>
                  <vs-input
                      ref="amount"
                      class="w-full"
                      type="text"
                      v-model.lazy="aliasCurrency"
                      v-money="money"
                      name="amount"
                      v-validate="'required'"
                      :min="0"
                      autocomplete="off"
                      placeholder="0" />
                </div>
            </div>

            <div class="vx-row mt-2">
              <div class="vx-col xxl:w-2/5 lg:w-3/5 md:w-2/3 sm:w-full w-full mb-6" >
                <label class="vs-input--label">Cuenta bancaria para retiro *</label>
                <v-select
                  name="account"
                  class="vs-custom"
                  :class="{'has-error': hasError('account'), 'is-success': isSuccess('account')}"
                  v-model.lazy="selectedAccountId"
                  v-validate="requiredRules"
                  :options="accounts"
                  :reduce="item => item.value"
                  :clearable="false"
                  placeholder="Selecciona una opción"
                  :searchable="false">
                </v-select>
                <v-error v-if="hasError('account')" :error="errorText('account')" />
              </div>
            </div>

            <div class="vx-row mt-2">
              <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5" >
                <div class="flex flex-wrap items-center justify-start">
                  <vs-button class="mt-auto" :disabled="amount <= 0 || selectedAccountId == null" @click="confirm()">Retirar</vs-button>
                </div>
              </div>
            </div>

            <div class="vx-row mt-2">
                <div class="vx-col w-full">
                  <p class="leading-loose">
                    <strong>Los retiros solicitados antes del mediodía (hora centro de México) se verán reflejados el mismo día, entre las 12:30 y 18:00 hrs. (hora centro de México).</strong><br>
                    <strong>Los retiros solicitados después del mediodía (hora centro de México) se verán reflejados en máximo un día hábil, entre las 12:30 y 18:00 hrs. (hora centro de México).</strong>
                  </p>
                </div>
            </div>
          </vx-card>
      </div>
      <vx-card v-else-if="isMounted" class="mt-4" title="No tienes una cuenta de banco registrada.">
          <div class="vx-row">
            <div class="vx-col w-full">
              <p>
                Por el momento no puedes retirar tus fondos ya que no tienes una cuenta de banco registrada en tu perfil de RedGirasol. 
                Recuerda que podrás registrar automáticamente una nueva cuenta al abonar fondos, siempre y cuando tú seas titular de la misma, ya que 
                de lo contrario tus fondos serán reembolsados.<br><br>
                Una vez que se haya registrado automáticamente la cuenta, podrás retirar tus fondos de manera segura, rápida y eficaz.
              </p>
              <vs-button class="mt-5" @click="goToFunds">Abonar fondos</vs-button>
            </div>
          </div>
      </vx-card>
     <!-- Componente para segundo factor -->
     <two-factor-auth
       :user-id="UserId"
       custom-indication-message="Por favor ingresa los 6 dígitos de tu Segundo Factor de Autenticación para ejecutar la operación."
       @on-code-validated="onAuth" />

      <!-- Componente para obtener la geolocalización -->
      <rg-geolocator ref="rgGeolocator" />
   </div>
</template>

<script>
import formatHelper from '@mixins/formatHelper';
import currencyHelper from '@mixins/currencyHelper';
import auth2faHelper from "@mixins/auth2faHelper";
import {VMoney} from 'v-money'
import ComplianceBanner from "@components/compliance/ComplianceBanner";
import TwoFactorAuth from "@components/2fa/TwoFactorAuth";
import {BankAccountStatus} from "@/bl/BankAccountStatus";
export default {
  mixins: [formatHelper, currencyHelper, auth2faHelper],
   directives: {money: VMoney},
  components: {ComplianceBanner, TwoFactorAuth},
   data(){
      return {
         aliasCurrency: 0,
         amount: 0,
        isMounted: false,
         money: {
            decimal: '.',
            thousands: ',',
            prefix: '$',
            suffix: '',
            precision: 2,
            masked: false
         },

        balance: null,
        verified: null,
        today: null,
        bank: null,
        clabe: null,

        user_person_type: null,
        user_id: null,

        validAccounts: [],
        accounts: [],
        mexicoCountryId: 700,
        selectedAccountId: null,
        shouldSelectAccount: false,
        colClass: "vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5",
        requiredRules: 'required',

        factorEnabled: false,
      }
   },
  async beforeMount(){
    this.isMounted = false
    this.showLoading(true)
    this.user_person_type = parseInt(this.UserPersonType);
    this.user_id = parseInt(this.UserId);
    await this.getWithdrawalInfo();
    this.factorEnabled = await this.getUser2FAStatus(this.UserId);
    await this.getUserAccounts();
    this.showLoading(false)
    this.isMounted = true
  },
   watch: {
    aliasCurrency: function(nval, oldval){
      this.amount = this.unMoneyFormat(nval);
    },
  },
  computed: {
    formatBalance(){
      return this.costFormat(this.balance);
    },
  },
   methods: {
     hasError(val){
       return this.errors.has(val);
     },
     errorText(val){
       return this.errors.first(val);
     },
     isSuccess(val){
       let ff = this.fields[val];
       return (ff && ff.required && ff.valid);
     },
     async getWithdrawalInfo(){
       try{
         let response = await axios.get('/api/inversionista/bank');
         this.balance = response.data.balance
         this.verified =  response.data.verified
         this.today = response.data.today
       }
       catch (e) {

       }
     },
     async getUserAccounts(){
       try {
         const res = await axios.get(`api/v1/userBankAccounts/${this.UserId}`);
         const accs = res.data;
         let formattedAccounts = [];
         accs.forEach(f => {
           let nacc = this.getFormattedAccount(f);
           formattedAccounts.push(nacc);
         });

         this.validAccounts = formattedAccounts.filter(f => f.is_verified);
         this.validAccounts.sort(function(a, b) {
           if(a.is_default){
             return -1
           }
           else if(b.is_default){
             return 1;
           }
           return 0;
         });

         this.validAccounts.forEach(opt => {
           this.accounts.push({value: parseInt(opt.id), label: `${opt.bank_name} | ${opt.clabe}`}); //conversion a sintaxis de vx-select
         })

         const defaultAccount = this.validAccounts.find(f => f.is_default);
         if(defaultAccount != null){
           this.selectedAccountId = defaultAccount.id;
         }

       }
       catch (e) {
       }
     },
     getFormattedAccount(f){
       let nacc = {
         id: f.id,
         bank_id: f.bank_id,
         is_default: f.is_default === 1,
         is_verified: f.verified === BankAccountStatus.VERIFIED,
       }

       if(f.bank && f.bank.country_id === this.mexicoCountryId){
         nacc.bank_name = f.bank.short_name;
         nacc.clabe = f.clabe;
       }
       else {
         nacc.bank_name = f.bank.name;
         nacc.clabe = f.bic_swift;
       }
       return nacc;
     },
     async confirm(){
       if(!this.validateWithdrawalAmount()){
         return
       }

       if(!this.validateAccount()){
         return;
       }

       if(!this.factorEnabled){
         this.$vs.dialog({
           color: "warning",
           type: "confirm",
           title: "Autenticación necesaria",
           text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
           acceptText: "Habilitar",
           cancelText: 'Cancelar',
           accept: this.goTo2FaPage,
         });
         return;
       }

       let acc = this.validAccounts.find(f => f.id === this.selectedAccountId);

       let text = `Estás por retirar ${this.aliasCurrency} MXN de tu cuenta.\n\n`;
       text += `Banco destino: ${acc.bank_name}\n\n`;
       text += `Cuenta CLABE: ${acc.clabe}\n\n`;

        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: "Retirar fondos",
          text: text,
          acceptText: "Aceptar",
          cancelText: "Cancelar",
          accept: this.start2faValidation
        });
     },
     validateWithdrawalAmount(){
       let a = this.amount;
       if (a < 0) {
         this.errorNotifDialog('Retiro inválido', 'No puedes retirar menos de $0.00')
         return false
       }

       if (a === 0) {
         this.errorNotifDialog('Retiro inválido', 'El monto de retiro es inválido')
         return false
       }

       if (a > this.balance) {
         this.errorNotifDialog('Retiro inválido', 'No puedes retirar un monto mayor a tu saldo actual.');
         return false
       }
       return true;
     },
     validateAccount(){
       if(this.selectedAccountId == null){
         this.errorNotifDialog('Cuenta bancaria requerida', 'Es necesario que indiques la cuenta bancaria a la que se hará el retiro.')
         return false
       }
       return true
     },
     async start2faValidation(){
        // se pide la geolocalizacion para la operacion
        this.$refs.rgGeolocator.askForGeolocation();
       this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", true);
     },
     async onAuth(code){
       await this.doExecuteWithdraw(code);
     },
     async doExecuteWithdraw(code) {
       let a = this.amount;
       let data = {
         amount: a,
         bank_account_id: this.selectedAccountId,
         code: code,
         geolocation: this.CurrentGeolocationData
       }
       this.injectAccountMetadataToPayload(data);

       this.showLoading(true)
       try {
         const response = await axios.post('/api/inversionista/bank', data);
         this.balance = response.data.balance;
         this.aliasCurrency = null;
         this.successNotifDialog('Retiro programado exitosamente', response.data.message);
         await this.onInvestorGeneralDataUpdated();
       }
       catch (error) {
         if (typeof error.response.data.message !== 'undefined') {
           this.errorNotifDialog('Retiro fallido', error.response.data.message);
         } else {
           this.failedOperationNotif();
         }
       }
       this.showLoading(false)
     },
     async goTo2FaPage(){
       await this.$router.push({name: 'auth2FaInversionista'});
     },
      async goToProfile(){
      },
      async goToFunds(){
        await this.$router.push({name: 'abonarFondos'});
      },
   }
}
</script>
